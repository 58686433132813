@import url("https://fonts.googleapis.com/css?family=Cabin:400,500,600,700&display=swap");
@import url("https://netdna.bootstrapcdn.com/font-awesome/3.2.1/css/font-awesome.css");
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-family: "Cabin", sans-serif;
  vertical-align: baseline;
  list-style-type: none;
  font-display: swap;
}

html {
  line-height: 1;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

caption, th, td {
  text-align: left;
  font-weight: normal;
  vertical-align: middle;
}

q, blockquote {
  quotes: none;
}

q:before, q:after, blockquote:before, blockquote:after {
  content: "";
  content: none;
}

a img {
  border: none;
}

/*article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section, summary {
display: block;
}*/
body {
  font-family: "Cabin", sans-serif;
  font-size: 1.2rem;
  line-height: 160%;
  background: #fff;
  color: #000;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-display: swap;
}

h1, h2, h3, h4, h5, h6 {
  font-family: "Cabin", sans-serif;
  font-display: swap;
}

h2 {
  font-size: 1.5rem;
  line-height: 100%;
  margin: 20px 0 0 0;
  color: #bd3613;
}

h3 {
  font-size: 1.8rem;
  line-height: 60%;
  color: #bd3613;
}

a {
  text-decoration: none;
  color: #000;
}

a:hover {
  color: #000;
}

p {
  font-family: "Cabin", sans-serif;
  /*  margin-bottom: 10px;*/
}

#root, section {
  display: flex;
  flex-direction: column;
}

.video {
  position: absolute;
  z-index: 0;
  background-size: 100% 100%;
  top: 0px;
  left: 0px;
  min-width: 100%;
  min-height: 100%;
  width: auto;
  height: auto;
}

.small {
  font-size: 1rem;
  color: #2176c7;
}

.footicon {
  display: inline-table;
  position: relative;
  text-align: center;
}

.footicon a {
  padding: 8px;
}

.footicon a img {
  width: 54px;
  height: 46px;
}

.burger {
  font-size: 60px;
  text-rendering: geometricPrecision;
  color: black;
}

* {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.layout {
  *zoom: 1;
  max-width: 100%;
  _width: 100%;
  margin-left: auto;
  margin-right: auto;
}

header {
  height: 100px;
}

svg {
  width: 100%;
  height: 100%;
  display: block;
  position: absolute;
  top: 0px;
  left: 0px;
}

.nav {
  display: flex;
  height: 100px;
  justify-content: space-between;
}

.nav > ul {
  /*  max-width: 60%;*/
  display: flex;
  height: 100px;
  justify-content: flex-end;
  padding-right: 2%;
}

.nav > ul li {
  margin-right: 24px;
  margin-top: 36px;
  font-weight: 700;
  /*padding-left: 2%;*/
}

.logo {
  height: 95px;
  margin-top: 4px;
  width: 100px;
}

.logo img {
  width: 100px;
  height: 95px;
}

.primary {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  overflow: hidden;
  height: 100vh;
  width: 100vw;
  background-color: #ebebeb;
}

.spanHome {
  display: block;
  width: 80%;
  position: relative;
  bottom: 0;
  top: 4%;
  left: 4%;
  right: 0;
  margin: auto 0;
  text-align: left;
  font-size: 40px;
  line-height: 50px;
  background-color: #223f9a;
  height: 100%;
  padding: 2%;
  color: white;
  border-bottom: 25px #ffdd18 solid;
}

@media (max-width: 770px) {
  .spanHome {
    font-size: 25px;
    line-height: 35px;
  }

  .footicon a img {
    width: 44px;
    height: 36px;
  }

  .footicon a {
    padding: 5px;
  }
}
.profile {
  background-color: #ffdd18;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.maskSpan {
  overflow: hidden;
}

.profile img {
  max-width: 85%;
  margin-bottom: 30px;
}

.hyp {
  font-weight: bold;
  background: white;
  /*  text-decoration: underline;*/
}

.holder {
  padding: 10px;
  text-align: justify;
  background: white;
}

.para {
  font-size: 1.2em;
  margin-bottom: 20px;
}

.dlResume {
  text-decoration: underline;
  font-weight: 700;
}

.container {
  padding: 30px;
  display: flex;
  flex-direction: column;
}

.secondary {
  width: 100%;
  font-size: 1.2rem;
  background-color: #ffdd18;
  position: relative;
  text-align: justify;
  padding: 30px;
}

.secondary > div {
  padding: 10px;
  background: white;
}

.secondary span {
  font-size: 1.2em;
  background: white;
}

.wgrid {
  background-color: #ffdd18;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  padding-bottom: 70px;
  align-items: center;
}

.worklistGrid {
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: center;
}

.worklistGrid li, .clientlistGrid li {
  padding: 30px;
}

.worklistGrid img, .clientlistGrid img {
  width: 100%;
}

/* style.css */
.skip-link {
  height: 95px;
  width: 120px;
  position: absolute;
  top: -95px;
  left: 0;
  background: #000000;
  color: white;
  padding: 8px;
  z-index: 100;
  font-weight: 700;
  margin: 0 auto;
}

.skip-link:focus {
  top: 0;
}

.skip-link:hover {
  color: white;
}

.worklistName {
  background-color: #223f9a;
  padding-bottom: 12px;
  overflow: hidden;
}

.worklistName span {
  padding-left: 30px;
  color: white;
  background-color: #ed1c24;
  padding-right: 30px;
  font-weight: 700;
}

.profileCont {
  display: flex;
  align-items: center;
  justify-content: center;
}

.clientDesc {
  font-family: "Cabin", sans-serif;
  font-size: 1.3rem;
  line-height: 160%;
  background: #223f9a;
  color: #fff;
  text-align: left;
}

.clientSamples {
  font-family: "Cabin", sans-serif;
  font-size: 1.3rem;
  background-color: #ed1c24;
  color: white;
  font-weight: 700;
}

.vidModal {
  overflow-y: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  width: 100vw;
  height: 100vh;
  z-index: 8;
  cursor: pointer;
}

.vidModal h1 {
  color: white;
}

.xBtn {
  position: absolute;
  right: 20px;
  top: 20px;
}

.modalBg {
  position: fixed;
  overflow-y: hidden;
  background-color: black;
  top: 0;
  left: 0;
  right: 0;
  width: 100vw;
  height: 100vh;
  opacity: 0.85;
}

.modalVid {
  z-index: 8;
}

.capClient {
  text-transform: capitalize;
}

.modalVid video {
  align-items: center;
  flex-direction: column;
  justify-content: center;
  width: 70vw;
  height: 70vh;
}

.worktitle {
  font-size: 2em;
  height: 51px;
  width: 200px;
  background: #223f9a;
  color: white;
  padding: 10px;
  text-align: center;
  margin-top: 50px;
}

.clientlistGrid li {
  cursor: pointer;
}

.greet {
  font-size: 2em;
  height: 51px;
  width: 200px;
  background: #223f9a;
  color: white;
  padding: 10px;
  text-align: center;
  margin-top: 50px;
}

.clientHeader {
  padding: 5%;
  text-align: left;
}

.soc-over {
  text-align: center;
}

footer {
  color: #000;
  background: #ebebeb;
  padding-top: 20px;
  padding-bottom: 100px;
}

.footcontainer {
  flex-direction: row;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 30px;
}

.footcontainer > div {
  display: flex;
}

.contactcont {
  flex-direction: column;
  display: flex !important;
  margin: 0 auto;
  width: 290px;
}

.contactcont span, .contactcont a {
  background: #ed1c24;
  color: white;
  margin: 0 auto !important;
}

.loadLogo {
  width: 100%;
  height: 100%;
  background-color: black;
  top: 0;
  left: 0;
  display: block;
  position: fixed;
  z-index: 9999;
}

.loadLogo img {
  width: 247px;
  height: 247px;
  margin: auto;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}
